import { Alert, Box, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import OfferGroupToolbar from '../OfferGroupToolbar';
import useCustomOffer from '../useCustomOffers';
import { useEffect, useState, useMemo } from 'react';
import { CustomOfferGroup } from '../types';
import { Link } from 'react-router-dom';
import { Edit, Delete, Visibility } from '@mui/icons-material';
import ConfirmModal from '../../../components/ConfirmModal';

const OfferGroupTable = () => {
  const client = useCustomOffer();
  const [groups, setGroups] = useState<CustomOfferGroup[]>([]);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const init = () => {
    setIsLoading(true);
    const apiCall = client.getOfferGroups();

    (async () => {
      try {
        const result = await apiCall.call();
        setGroups(result);
        setIsLoading(false);
      } catch (error) {}
    })();

    return apiCall.abort;
  };

  const onEditOfferGroupDone = (updatedGroup: CustomOfferGroup) => {
    const newGroups = groups.map((item) => {
      if (updatedGroup._id === item._id) return updatedGroup;
      return item;
    });
    setGroups(newGroups);
  };

  const selectedRowData = useMemo(() => {
    return groups.find((item) => item._id === selectedRow);
  }, [selectedRow, groups]);

  const deleteGroup = async (_id: CustomOfferGroup['_id']) => {
    await client.deleteOfferGroup().call(_id);
    const newRows = groups.filter((r) => r._id !== _id);
    setGroups(newRows);
    if (selectedRow === _id) {
      setSelectedRow(null);
    }
  };

  const onAddOfferGroupDone = (newGroup: CustomOfferGroup) => {
    setGroups([...groups, newGroup]);
  };

  useEffect(() => {
    return init();
  }, []);

  const cols: GridColDef<CustomOfferGroup>[] = [
    {
      headerName: 'Key',
      field: 'key',
      flex: 1,
      renderCell: (params) => {
        return (
          <Link to={params.row._id} style={{ cursor: 'pointer' }}>
            {params.row.key}
          </Link>
        );
      },
    },
    {
      headerName: 'Label',
      field: 'label',
      flex: 1,
    },
    {
      headerName: 'Actions',
      field: '_id',
      renderCell: ({ row }) => {
        return (
          <Box>
            <ConfirmModal
              iconButton
              title="Show API Key"
              icon={<Visibility />}
              onConfirm={() => null}
            >
              {row.apiKey ? row.apiKey : 'No API key available.'}
            </ConfirmModal>
            <ConfirmModal
              iconButton
              title="Delete"
              icon={<Delete />}
              onConfirm={() => deleteGroup(row._id)}
            >
              <Alert severity="error">
                Are you sure you want to delete this offer?
              </Alert>
            </ConfirmModal>
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <DataGrid
        columns={cols}
        rows={groups}
        getRowId={(r) => r._id}
        autoHeight
        rowSelectionModel={selectedRow ? [selectedRow] : []}
        onRowSelectionModelChange={(newSelection) => {
          const selectedId = newSelection[0] || null;
          setSelectedRow((prev) =>
            prev === selectedId ? null : (selectedId as any)
          );
        }}
        loading={isLoading}
        slots={{
          toolbar: () => (
            <OfferGroupToolbar
              onAddOfferGroupDone={onAddOfferGroupDone}
              onEditOfferGroupDone={onEditOfferGroupDone}
              selectedRow={selectedRowData}
            />
          ),
        }}
      />
    </Box>
  );
};

export default OfferGroupTable;

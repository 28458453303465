import React from 'react';
import ReactDOM from 'react-dom';
import { Snackbar, Alert, AlertColor } from '@mui/material';

type MessageProps = {
  message: string;
  severity?: AlertColor;
  duration?: number;
};

export function showMessage({
  message,
  severity = 'success',
  duration = 3000,
}: MessageProps) {
  const div = document.createElement('div');
  document.body.appendChild(div);

  const removeMessage = () => {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
  };

  ReactDOM.render(
    <Snackbar
      open
      autoHideDuration={duration}
      onClose={removeMessage}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={removeMessage} severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>,
    div
  );
}

import { OfferContext } from '../context';
import { useContext, useState } from 'react';
import SimpleModal from '../../../components/SimpleModal';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Autocomplete,
  Button,
  IconButton,
  Divider,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function AddOffer({ open, handleClose, onSubmit }) {
  const offerContext = useContext(OfferContext);
  const { traffic, domains } = offerContext;

  const [values, setValues] = useState({
    offerLink: '',
    trafficSourceId: '',
    name: '',
    fallBackUrl: '',
    redirectDomainId: '',
    entryDomainId: '',
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleTrafficSourceChange = (event, newValue) => {
    setValues({
      ...values,
      trafficSourceId: newValue ? newValue._id : '',
    });
  };

  const handleRedirectDomainChange = (event, newValue) => {
    setValues({
      ...values,
      redirectDomainId: newValue ? newValue._id : '',
    });
  };
  const handleEntryDomainChange = (event, newValue) => {
    setValues({
      ...values,
      entryDomainId: newValue ? newValue._id : '',
    });
  };

  const activeDomains = domains.filter((item) => item.active);
  const entryDomains = domains.filter(
    (item) => item.active && item.isEntry === true
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        Add offer
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
            setValues({ name: '', offerLink: '', fallBackUrl: '' });
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ mt: 1 }} />
      <DialogContent>
        <Box gap={2} display={'flex'} flexDirection={"column"}>
          <Typography>
            Adding an offer will generate a redirect link for it
          </Typography>
          <TextField
            name="name"
            onChange={onChange}
            label="Name"
            value={values.name}
            fullWidth
          />
          <TextField
            onChange={onChange}
            fullWidth
            label="Offer link"
            value={values.offerLink}
            name="offerLink"
          />
          <TextField
            onChange={onChange}
            fullWidth
            label="Fallback Url"
            value={values.fallBackUrl}
            name="fallBackUrl"
          />
          <Autocomplete
            options={traffic}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            onChange={handleTrafficSourceChange}
            renderInput={(params) => (
              <TextField {...params} label="Traffic Source" fullWidth />
            )}
          />
          <Autocomplete
            options={activeDomains}
            getOptionLabel={(option) => option.domainName}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            onChange={handleRedirectDomainChange}
            renderInput={(params) => (
              <TextField {...params} label="Redirect Domain" fullWidth />
            )}
          />
          <Autocomplete
            options={entryDomains}
            getOptionLabel={(option) => option.domainName}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            onChange={handleEntryDomainChange}
            renderInput={(params) => (
              <TextField {...params} label="Entry domain" fullWidth />
            )}
          />
        </Box>
      </DialogContent>
      <Divider sx={{ mt: 2 }} />
      <DialogActions sx ={{p: 2}}>
        <Button fullWidth
          variant="contained"
          onClick={() => onSubmit(values, handleClose)}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useState } from 'react';
import {
  Box,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Autocomplete,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '../../../components/LoadingButton';
import { OfferGroupTraffic } from '../types';
import { TrafficSource } from '../../traffic/types';

type Props = {
  handleClose: () => any;
  onSubmit: (newData: OfferGroupTraffic) => any;
  trafficData: OfferGroupTraffic;
  trafficSource: TrafficSource;
};

const EditGroupTraffic = ({
  handleClose,
  trafficData,
  onSubmit,
  trafficSource,
}: Props) => {
  const [values, setValues] = useState(trafficData);
  const [loading, setLoading] = useState(false);

  const handleAutocompleteChange = (
    event: React.SyntheticEvent,
    newValue: { id: string; tag: string } | null
  ) => {
    setValues({
      ...values,
      clickId: newValue ? newValue.tag : '',
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit({ id: values.id, clickId: values.clickId });
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Edit Traffic Source Data</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Autocomplete
          value={
            trafficSource.parameterOptions.find(
              (option) => option.tag === values.clickId
            ) || null
          }
          onChange={handleAutocompleteChange}
          options={trafficSource.parameterOptions}
          getOptionLabel={(option) => option.tag}
          isOptionEqualToValue={(option, value) => option.tag === value.tag}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Click Id"
              margin="normal"
              size="small"
            />
          )}
          fullWidth
          disableCloseOnSelect
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={handleSubmit}
          color="primary"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditGroupTraffic;

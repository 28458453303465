import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';

import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  initialData?: Date;
  onSubmit: (newDate: Date) => any;
  handleClose: () => void;
  open: boolean;
  title?: string;
};

const DatePicker = ({
  initialData = new Date(),
  onSubmit,
  open,
  title,
  handleClose,
}: Props) => {
  const [date, setDate] = useState(initialData);

  const handleSubmit = () => {
    onSubmit(date);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {title || 'Select a date'}
        <IconButton edge="end" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          sx={{ width: '100%' }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Calendar date={date} onChange={setDate} />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ marginRight: 1 }}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DatePicker;

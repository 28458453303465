import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '../../../components/LoadingButton';
import useTraffic from '../../traffic/useTraffic';

type Props = {
  open: boolean;
  handleClose: () => any;
  onSubmit: (newTraffic: any) => any;
};

const AddCustomOfferGroupTrafficSource = ({
  open,
  handleClose,
  onSubmit,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [traffic, setTraffic] = useState([]);
  const [selectedTraffic, setSelectedTraffic] = useState('');
  const [error, setError] = useState('');
  const { getTraffic } = useTraffic();

  const init = () => {
    setLoading(true);
    const apiCall = getTraffic();

    (async function () {
      try {
        const traffic = await apiCall.call();
        setTraffic(traffic);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();

    return apiCall.abort;
  };

  useEffect(() => {
    return init();
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTraffic(e.target.value);
    if (e.target.value) {
      setError('');
    }
  };

  const handleSubmit = async () => {
    if (!selectedTraffic) {
      setError('Traffic source is required.');
      return;
    }

    setLoading(true);
    try {
      await onSubmit({ id: selectedTraffic, clickId: '' });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setSelectedTraffic('');
    setError('');
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Add Traffic Source Data</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          label="Traffic"
          onChange={onChange}
          name="traffic"
          select
          fullWidth
          value={selectedTraffic}
          margin="normal"
          size="small"
          error={!!error}
          helperText={error}
        >
          {traffic.map((p: any) => (
            <MenuItem key={p._id} value={p._id}>
              {p.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCancel} color="secondary" variant="contained">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={handleSubmit}
          color="primary"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomOfferGroupTrafficSource;

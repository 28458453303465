import React, { useState } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import TooltipIconButton from './TooltipIconButton';
import CloseIcon from '@mui/icons-material/Close';

type ConfirmDialogProps = {
  iconButton?: boolean;
  icon?: React.ReactNode;
  title: string;
  children: React.ReactNode;
  onConfirm: () => void;
  dialogProps?: Record<string, any>;
};

function ConfirmDialog({
  iconButton = true,
  icon,
  title,
  children,
  onConfirm,
  dialogProps = {},
}: ConfirmDialogProps) {
  const [open, setOpen] = useState(false);

  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  const handleConfirm = () => {
    onConfirm();
    closeDialog();
  };

  return (
    <>
      {iconButton ? (
        <TooltipIconButton title={title} onClick={openDialog} icon={icon} />
      ) : (
        <Button onClick={openDialog}>{title}</Button>
      )}
      <Dialog open={open} onClose={closeDialog} {...dialogProps}>
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={closeDialog} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmDialog;

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Button,
  Divider,
  IconButton,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '../../../components/LoadingButton';

type Props = {
  open: boolean;
  handleClose: () => any;
  onSubmit: (hideOffer: boolean) => any;
};

const BulkEditCustomOffer = ({ handleClose, open, onSubmit }: Props) => {
  const [loading, setLoading] = useState(false);
  const [hideOffer, setHideOffer] = useState(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHideOffer(event.target.value === 'true');
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(hideOffer);
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Bulk Edit Custom Offer
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Alert severity="warning" sx={{ mb: 2 }}>
          Hiding Offer makes it <strong>inactive</strong>.
        </Alert>
        <FormControl component="fieldset">
          <FormLabel component="legend">Show or Hide Offer</FormLabel>
          <RadioGroup
            aria-label="hideOffer"
            name="hideOffer"
            value={hideOffer}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="false" control={<Radio />} label="Show" />
            <FormControlLabel value="true" control={<Radio />} label="Hide" />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={handleSubmit}
          color="primary"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default BulkEditCustomOffer;

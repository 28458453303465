import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Divider,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CSVUploader, { CSVUploadResult } from '../../../components/CsvUploader';
import LoadingButton from '../../../components/LoadingButton';

type Props = {
  open: boolean;
  handleClose: () => any;
  onSubmit: (data: Record<string, any>[]) => any;
};

const ImportCustomOffer = ({ open, handleClose, onSubmit }: Props) => {
  const [data, setData] = useState<CSVUploadResult | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!data) return;

    try {
      setLoading(true);
      const [head, ...body] = data.data as string[][];

      const headers = head.map((h) => h.trim());

      const filteredBody = body.filter((b) =>
        b.some((i) => i != null && i !== '')
      );

      const parsedBody = filteredBody.map((b: string[]) => {
        const entries = b.map((value, i) => {
          const currentHead = headers[i];
          return [currentHead, value.trim()];
        });
        return Object.fromEntries(entries);
      });

      await onSubmit(parsedBody);
      handleClose();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Import Custom Offers
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Upload your CSV file to import custom offers.
        </Typography>
        <CSVUploader onChange={setData} />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleSubmit}
          color="primary"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ImportCustomOffer;

import useApi from '../../hooks/useApi';
import { CustomOffer, CustomOfferGroup, OfferGroupReport } from './types';

const BASE_URL = '/api/custom-offers';

const useCustomOffer = () => {
  const { api, createApiCall } = useApi();

  const createOfferGroup = createApiCall(async ({ signal }, dto) => {
    const {
      data: { customOfferGroup },
    } = await api.post(BASE_URL, dto, { signal });

    return customOfferGroup;
  });

  const getOfferGroups = createApiCall(async ({ signal }) => {
    const {
      data: { customOfferGroups },
    } = await api.get(BASE_URL, { signal });

    return customOfferGroups;
  });

  const getOfferGroup = createApiCall(async ({ signal }, id) => {
    const {
      data: { customOfferGroup },
    } = await api.get(`${BASE_URL}/${id}`, { signal });

    return customOfferGroup;
  });

  const getCustomOffers = createApiCall(async ({ signal }, id) => {
    const {
      data: { customOffers },
    } = await api.get(`${BASE_URL}/${id}/rows`, { signal });

    return customOffers;
  });

  const importCustomOffers = createApiCall(async ({ signal }, id, dto) => {
    const {
      data: { customOffers },
    } = await api.post(`${BASE_URL}/${id}/rows`, dto, { signal });

    return customOffers;
  });

  const deleteCustomOffer = createApiCall(async ({ signal }, id) => {
    await api.delete(`${BASE_URL}/rows/${id}`, { signal });
    return true;
  });

  const deleteOfferGroup = createApiCall(async ({ signal }, id) => {
    await api.delete(`${BASE_URL}/${id}`, { signal });
    return true;
  });

  const updateOfferGroup = createApiCall(
    async ({ signal }, id, newOfferGroup) => {
      await api.put(`${BASE_URL}/${id}`, newOfferGroup, { signal });
      return true;
    }
  );

  const updateCustomOffer = createApiCall(
    async ({ signal }, id: string, dto: Pick<CustomOffer, 'data' | 'api'>) => {
      const {
        data: { customOffer },
      } = await api.put(`${BASE_URL}/rows/${id}`, dto, { signal });
      return customOffer as CustomOffer;
    }
  );

  const getReports = createApiCall(
    async ({ signal }, groupId: CustomOfferGroup['_id']) => {
      const {
        data: { offerGroupReports },
      } = await api.get(`${BASE_URL}/${groupId}/reports`, { signal });

      return offerGroupReports as OfferGroupReport[];
    }
  );

  const getReportRows = createApiCall(
    async ({ signal }, reportId: OfferGroupReport['_id']) => {
      const {
        data: { customReportRows },
      } = await api.get(`${BASE_URL}/reports/${reportId}`, { signal });

      return customReportRows as OfferGroupReport['data'];
    }
  );

  const deleteReport = createApiCall(async ({ signal }, id: string) => {
    await api.delete(`${BASE_URL}/reports/${id}`, { signal });
  });

  const getClicksSummary = createApiCall(
    async (
      { signal },
      groupId: CustomOfferGroup['_id'],
      startDate: string,
      endDate: string
    ) => {
      const {
        data: { clicksSummary },
      } = await api.get(`${BASE_URL}/${groupId}/clicks-summary`, {
        signal,
        params: {
          startDate,
          endDate,
        },
      });

      return clicksSummary as {
        actual: {
          revenue: number;
          clicks: number;
          merchantId: string;
          merchantName: string;
        };
        reported: {
          revenue: number;
          clicks: number;
          merchantId: string;
          merchantName: string;
        }[];
      };
    }
  );

  const generateReport = createApiCall(
    async ({ signal }, groupId: CustomOfferGroup['_id'], date: string) => {
      const {
        data: { generatedReport },
      } = await api.get(`${BASE_URL}/reports/generate/${groupId}`, {
        signal,
        params: {
          date,
        },
      });

      return generatedReport;
    }
  );

  return {
    createOfferGroup,
    getOfferGroups,
    getCustomOffers,
    importCustomOffers,
    getOfferGroup,
    deleteOfferGroup,
    updateOfferGroup,
    deleteCustomOffer,
    updateCustomOffer,
    deleteReport,
    getReports,
    getReportRows,
    getClicksSummary,
    generateReport
  };
};

export default useCustomOffer;
